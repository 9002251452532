$(document).ready(function() {

	$('.select-field').selectpicker();

	// calendar
	var dirRtl = 'en';
	var arrowDirR = 'glyphicon glyphicon-chevron-left';
	var arrowDirL = 'glyphicon glyphicon-chevron-right';

	if ($("html").attr('dir') == 'rtl') {
		dirRtl = 'he';
		arrowDirR = "glyphicon glyphicon-chevron-right";
		arrowDirL = "glyphicon glyphicon-chevron-left";
	}

	$('.search-datepicker').datetimepicker({
		showClose: true,
		showClear: true,
		useCurrent: false,
		keepOpen: true,
		format: 'DD/MM/YYYY',
		debug: false,
		locale: dirRtl,
		icons: {
			previous: arrowDirR,
			next: arrowDirL,
		},
	});
	// end calendar

	var params = $('#params');
	var choise_params = $('#choise-params');

	params.on('click', function(e) {
		// $(this).parent().toggleClass('open');
		$(this).parent().toggleClass('open');
	});


	$(document).mouseup(function(e) { // отслеживаем событие клика по веб-документу
		var block = $(".dropdown-param__menu"); // определяем элемент, к которому будем применять условия (можем указывать ID, класс либо любой другой идентификатор элемента)
		var target = e.target;


		if (target == $(".dropdown-param__btn")[0]) {
			// var pars = $('#dropdown-param')[0];
			// console.log($('#dropdown-param')[0]);
			// console.log(pars.classList);	
			// pars.classList.remove("open");
			// $('#dropdown-param')[0].attr('class', 'dropdown dropdown-param');
		}



		if (!block.is(target) && block.has(target).length === 0) { // проверка условия если клик был не по нашему блоку && проверка условия если клик не по его дочерним элементам
			block.parent().removeClass('open');
		}
	});

	document.addEventListener('DOMContentLoaded', function() {

		var dropArr = document.getElementsByClassName('dropdown-param');
		dropArr = Array.prototype.slice.call(dropArr);

		dropArr.forEach(function(el) {
			// get toggle button
			var btn = el.getElementsByClassName('dropdown-param__btn')[0];
			// get menu
			var menu = el.getElementsByClassName('dropdown-param__menu')[0];
			// is dropdown menu style top changed
			var changed = false;
			// original dropdown menu style top
			var original_top = menu.style.top;
			// add event listener when dropdown toggles 'open' class
			new MutationObserver(function(mutations) {
				if (mutations[0].attributeName === 'class') {
					if (el.classList.contains('open')) {
						// dropdown opened

						// get space between btn bottom and window bottom corner
						var height_to_end = window.innerHeight -
							btn.getBoundingClientRect().bottom;

						// get menu height when it becomes visible in browser
						var menu_height = menu.offsetHeight;
						menu_height += 0;

						// if menu_height > height_to_end - display menu up not down
						if (menu_height > height_to_end) {
							menu.style.top = '-' + menu_height + 'px';
							changed = true;
						}
					} else {
						// dropdown closed

						if (changed) {
							// restore style only if it was changed
							menu.style.top = original_top;
							changed = false;
						}
					}
				}
			}).observe(el, {
				attributes: true
			});
		});
	});



	$("body").on('click', '.btn-number__cart', function(e) {
		e.preventDefault();
		fieldName = $(this).attr('data-field');
		type = $(this).attr('data-type');
		var input = $("input[name='" + fieldName + "']");
		var currentVal = parseInt(input.val());

		if (!isNaN(currentVal)) {
			if (type == 'minus') {
				if (currentVal > input.attr('min')) {
					input.val(currentVal - 1).change();
				}
				if (parseInt(input.val()) == input.attr('min')) {
					$(this).attr('disabled', true);
				}
			} else if (type == 'plus') {
				//
				$("button[name='shc-minus']").attr('disabled', false);
				//
				if (currentVal < input.attr('max')) {
					input.val(currentVal + 1).change();
				}
				if (parseInt(input.val()) == input.attr('max')) {
					$(this).attr('disabled', true);
				}
			}
		} else {
			input.val(0);
		}
	});

});