$(document).ready(function() {
  $('.prod-gallery').slick({
  	slidesToShow: 1,
  	slidesToScroll: 1,
  	arrows: true,
  	dots: false,
  	fade: true,
  	rtl: ($("html").attr('dir') == 'rtl' ? true : false),
  	autoplay: false,
  	infinite: true
  });

  $('#prod-gallery-navi').slick({
  	slidesToShow: 6,
  	slidesToScroll: 1,
  	dots: false,
  	arrows: false,
  	focusOnSelect: true,
  	infinite: true,
  	rtl: ($("html").attr('dir') == 'rtl' ? true : false),
  	responsive: [{
  			breakpoint: 1200,
  			settings: {
  				slidesToShow: 5,
  			}
  		},
  		{
  			breakpoint: 992,
  			settings: {
  				slidesToShow: 4,
  			}
  		},
  		{
  			breakpoint: 768,
  			settings: {
  				slidesToShow: 3,
  			}
  		},
  		{
  			breakpoint: 600,
  			settings: {
  				slidesToShow: 2,
  			}
  		},
  		{
  			breakpoint: 430,
  			settings: {
  				slidesToShow: 2,
  			}
  		}
  	]
  });

  // $('.prod-gallery-lightbox').lightGallery({
  // 	thumbnail: true,
  // 	selector: '.prod-gallery__img-big'
	// });

  $('.prod-gallery-navi__img-small').click(function(){
    $('.prod-gallery').slick('unslick');
    $('.prod-gallery').css('display','none')
    $('.prod-gallery'+$(this).data('cs')).css('display','block')
    setTimeout(function(){
      $('.prod-gallery').slick("unslick");
      $('.prod-gallery').slick({
      	slidesToShow: 1,
      	slidesToScroll: 1,
      	arrows: true,
      	dots: false,
      	fade: true,
      	rtl: ($("html").attr('dir') == 'rtl' ? true : false),
      	autoplay: true,
      	infinite: true,
      });
      $('.prod-gallery').slick('setPosition');
    },250);
  });

});
