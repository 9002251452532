$(document).ready(function() {

	$(window).on("load", function() {
		$("a[rel='m_PageScroll2id']").mPageScroll2id({
			offset: 200,
		});
	});

	$('.services-include').slick({
		slidesToShow: 10,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		focusOnSelect: false,
		infinite: true,
		rtl: ($("html").attr('dir') == 'rtl' ? true : false),
		responsive: [{
				breakpoint: 1300,
				settings: {
					slidesToShow: 9,
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 8,
				}
			},
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 7,
				}
			},
			{
				breakpoint: 993,
				settings: {
					slidesToShow: 6,
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 510,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 416,
				settings: {
					slidesToShow: 2,
				}
			}
		]
	});



	// $(window).scroll(function() {
	// 	var x = $(this).scrollTop(); /* scroll pos */
	// 	if(x>300){
	// 		$('.banner-form-wrap').css({position: 'fixed', bottom: 'auto', top: 125});
	// 	}else{
	// 		$('.banner-form-wrap').css({position: 'absolute', bottom: 0, top: 'auto' }); 
	// 	}
	// });
	y=0;
	var doc = document.documentElement;

	$(window).scroll(function() {
	
		var x = $(this).scrollTop();
		var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
		
		// if(x > 380 && x < 505){
		// 	y=505;
		// }else{
		// 	y=x;
		// }
		console.log(top);
		if(top>=505){
			$('.hotel-nav').addClass("fixtop");  
		}else{ 
			$('.hotel-nav').removeClass( "fixtop" ); 
		} 
		
		if(top>=165){
			$('.hotel-nav').addClass("fixtopm");  
		}else{ 
			$('.hotel-nav').removeClass( "fixtopm" ); 
		} 
		// if(x>505){
		// 	$('.hotel-nav').css({position: 'fixed',  top: 100,  background: '#fff',left: '50%',transform: 'translateX(-50%)'});  
		// }else{
		// 	$('.hotel-nav').css({position: 'relative', top: 'auto',  background: 'transparent',left: 'auto',transform: 'none' }); 
		// } 
	}); 


});