$(function() {

	// Side Menu Control
	var myDir = ($("html").attr('dir') == 'rtl' ? 'right' : 'left');
	var mySlidebars = new $.slidebars();
	$('.mobile-menu-btn').on('click', function() {
		mySlidebars.slidebars.toggle(myDir);
		// $('.header__lang-block').toggleClass('header__lang-block_black');
	});

	$(window).on("scroll", scrollFunction);
	// $(window).on("scroll", function() {
	// 	$(".booking-form").removeClass("booking-form_open");
	// });

	function scrollFunction() {
		// var bookingHead = $('#bookingHead');
		// var booking = $('#booking');
		// var bookingHolder = $('#bookingHolder');
		// var homepagec = $( "#homepageid" ).hasClass( "homepagec" );
		// if(homepagec){
		// 	if ($(window).scrollTop() > 150 && $(window).width() > 992) {
		// 		$(".header").addClass("scrolling");
		// 		$(".mobile-menu-btn").addClass("mobile-menu-btn_accent");
		// 		$(".booking-form").addClass("booking-form_scroll");
		// 		booking.appendTo(bookingHead);
		// 	} else {
		// 		$(".header").removeClass("scrolling");
		// 		$(".mobile-menu-btn").removeClass("mobile-menu-btn_accent");
		// 		$(".booking-form").removeClass("booking-form_scroll");
		// 		booking.prependTo(bookingHolder);
		// 		if ($(window).width() <= 992) {
		// 			booking.appendTo(bookingHead);
		// 			$(".booking-form").addClass("booking-form_scroll");
		// 		}
		// 	}
		// }
	
	}

	$('.headerclick').on('click', function(e) {
		$('.booking-form').toggleClass('booking-form_open');
		// $('.booking-form').toggleClass('booking-form_scroll');
	})
});